@import './tokens.scss';

@function toRGB ($color) {
  @return red($color) + ", " + green($color) + ", " + blue($color);
}

$text-black: color-map(black, 700);
$text-white: color-map(white, 100);
$text-success: color-map(success, 0);

$primary-50:  color-map(blue, 50);
$primary-100: color-map(blue, 100);
$primary-200: color-map(blue, 200);
$primary-300: color-map(blue, 300);
$primary-400: color-map(blue, 400);
$primary-500: color-map(blue, 500);
$primary-600: color-map(blue, 600);
$primary-700: color-map(blue, 700);
$primary-800: color-map(blue, 800);
$primary-900: color-map(blue, 900);
$primary-950: color-map(blue, 950);

$primary-palette: (
  50:  $primary-50,
  100: $primary-100,
  200: $primary-200,
  300: $primary-300,
  400: $primary-400,
  500: $primary-500,
  600: $primary-600,
  700: $primary-700,
  800: $primary-800,
  900: $primary-900,
  950: $primary-950,
  contrast: (
    50:  $text-black,
    100: $text-black,
    200: $text-black,
    300: $text-black,
    400: $text-black,
    500: $text-white,
    600: $text-white,
    700: $text-white,
    800: $text-white,
    900: $text-white,
    950: $text-white,
  )
);

$accent-50:  color-map(yellow, 50);
$accent-100: color-map(yellow, 100);
$accent-200: color-map(yellow, 200);
$accent-300: color-map(yellow, 300);
$accent-400: color-map(yellow, 400);
$accent-500: color-map(yellow, 500);
$accent-600: color-map(yellow, 600);
$accent-700: color-map(yellow, 700);
$accent-800: color-map(yellow, 800);
$accent-900: color-map(yellow, 900);
$accent-950: color-map(yellow, 950);

$accent-palette: (
  50:  $accent-50,
  100: $accent-100,
  200: $accent-200,
  300: $accent-300,
  400: $accent-400,
  500: $accent-500,
  600: $accent-600,
  700: $accent-700,
  800: $accent-800,
  900: $accent-900,
  950: $accent-950,
  contrast: (
    50:  $text-black,
    100: $text-black,
    200: $text-black,
    300: $text-black,
    400: $text-black,
    500: $text-black,
    600: $text-black,
    700: $text-black,
    800: $text-white,
    900: $text-white,
    950: $text-white,
  )
);

$warn-50:  color-map(red, 50);
$warn-100: color-map(red, 100);
$warn-200: color-map(red, 200);
$warn-300: color-map(red, 300);
$warn-400: color-map(red, 400);
$warn-500: color-map(red, 500);
$warn-600: color-map(red, 600);
$warn-700: color-map(red, 700);
$warn-800: color-map(red, 800);
$warn-900: color-map(red, 900);
$warn-950: color-map(red, 950);

$warn-palette: (
  50:  $warn-50,
  100: $warn-100,
  200: $warn-200,
  300: $warn-300,
  400: $warn-400,
  500: $warn-500,
  600: $warn-600,
  700: $warn-700,
  800: $warn-800,
  900: $warn-900,
  950: $warn-950,
  contrast: (
    50:  $text-black,
    100: $text-black,
    200: $text-black,
    300: $text-black,
    400: $text-black,
    500: $text-white,
    600: $text-white,
    700: $text-white,
    800: $text-white,
    900: $text-white,
    950: $text-white,
  )
);

$grey-50:  color-map(grey, 50);
$grey-100: color-map(grey, 100);
$grey-200: color-map(grey, 200);
$grey-300: color-map(grey, 300);
$grey-400: color-map(grey, 400);
$grey-500: color-map(grey, 500);
$grey-600: color-map(grey, 600);
$grey-700: color-map(grey, 700);
$grey-800: color-map(grey, 800);
$grey-900: color-map(grey, 900);
$grey-950: color-map(grey, 950);

:root {
  --color-black:   $text-black;
  --color-white:   $text-white;
  --color-success: $text-success;

  --color-primary-50:  $primary-50;
  --color-primary-100: $primary-100;
  --color-primary-200: $primary-200;
  --color-primary-300: $primary-300;
  --color-primary-400: $primary-400;
  --color-primary-500: $primary-500;
  --color-primary-600: $primary-600;
  --color-primary-700: $primary-700;
  --color-primary-800: $primary-800;
  --color-primary-900: $primary-900;
  --color-primary-950: $primary-950;

  --color-accent-50:  $accent-50;
  --color-accent-100: $accent-100;
  --color-accent-200: $accent-200;
  --color-accent-300: $accent-300;
  --color-accent-400: $accent-400;
  --color-accent-500: $accent-500;
  --color-accent-600: $accent-600;
  --color-accent-700: $accent-700;
  --color-accent-800: $accent-800;
  --color-accent-900: $accent-900;
  --color-accent-950: $accent-950;

  --color-warn-50:  $warn-50;
  --color-warn-100: $warn-100;
  --color-warn-200: $warn-200;
  --color-warn-300: $warn-300;
  --color-warn-400: $warn-400;
  --color-warn-500: $warn-500;
  --color-warn-600: $warn-600;
  --color-warn-700: $warn-700;
  --color-warn-800: $warn-800;
  --color-warn-900: $warn-900;
  --color-warn-950: $warn-950;

  --color-grey-50:  $grey-50;
  --color-grey-100: $grey-100;
  --color-grey-200: $grey-200;
  --color-grey-300: $grey-300;
  --color-grey-400: $grey-400;
  --color-grey-500: $grey-500;
  --color-grey-600: $grey-600;
  --color-grey-700: $grey-700;
  --color-grey-800: $grey-800;
  --color-grey-900: $grey-900;
  --color-grey-950: $grey-950;
}