
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@tailwind base;
@tailwind components;
@tailwind utilities;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import './theme/color-map.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vendor-primary: mat.define-palette($primary-palette);
$vendor-accent:  mat.define-palette($accent-palette);

// The warn palette is optional (defaults to red).
$vendor-warn: mat.define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$vendor-theme: mat.define-light-theme((
  color: (
    primary: $vendor-primary,
    accent:  $vendor-accent,
    warn:    $vendor-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vendor-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; color: $text-black; }

.font-poppins {
  font-family: "Poppins", sans-serif; 
}

.font-grotesk {
  font-family: "Space Grotesk", sans-serif;
}

/* *******************************************************
 * OVERRIDE SOME MATERIAL SETTINGS
 * ******************************************************/
// Seems to be a bug in Material...this fixes it
.mat-form-field-appearance-outline .mdc-notched-outline__notch {
  border-right-width: 0 !important;
}

.mat-mdc-dialog-actions {
  flex-direction: row-reverse !important;
  justify-content: flex-start !important;
}

.mat-mdc-form-field.no-hints {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-paginator-range-label {
  margin: 4px 8px 0 !important;
}

.mat-mdc-paginator-page-size {
  & {
    margin-top: 4px;
  }
}

@media (max-width: 520px) {
  .mat-mdc-paginator-page-size { display: none !important; }
}

/* *******************************************************
 * MATERIAL BUTTON DROP SHADOW OVERRIDES TO MATCH SYZL
 * ******************************************************/
/* PRIMARY BUTTONS - raised */
button.mat-mdc-raised-button.mat-primary {
  box-shadow: 4px 4px 0px $primary-200;
}
button.mat-mdc-raised-button.mat-primary:hover {
  box-shadow: 4px 4px 0px $primary-300;
}
button.mat-mdc-raised-button.mat-primary:focus {
  box-shadow: 2px 2px 0px $primary-300;
}

/* PRIMARY BUTTONS - stroked */
button.mat-mdc-outlined-button.mat-primary {
  border-color: $primary-500;
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $primary-100;
}
button.mat-mdc-outlined-button.mat-primary:hover {
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $primary-200;
}
button.mat-mdc-outlined-button.mat-primary:focus {
  box-shadow: 1px 1px 0px 0 #FFF, 2px 2px 0px $primary-200;
}

/* ACCENT BUTTONS - raised */
button.mat-mdc-raised-button.mat-accent {
  box-shadow: 4px 4px 0px $accent-200;
}
button.mat-mdc-raised-button.mat-accent:hover {
  box-shadow: 4px 4px 0px $accent-300;
}
button.mat-mdc-raised-button.mat-accent:focus {
  box-shadow: 2px 2px 0px $accent-400;
}

/* ACCENT BUTTONS - stroked */
button.mat-mdc-outlined-button.mat-accent {
  border-color: $accent-500;
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $accent-100;
}
button.mat-mdc-outlined-button.mat-accent:hover {
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $accent-200;
}
button.mat-mdc-outlined-button.mat-accent:focus {
  box-shadow: 1px 1px 0px 0 #FFF, 2px 2px 0px $accent-200;
}

/* WARN BUTTONS - raised */
button.mat-mdc-raised-button.mat-warn {
  box-shadow: 4px 4px 0px $warn-200;
}
button.mat-mdc-raised-button.mat-warn:hover {
  box-shadow: 4px 4px 0px $warn-300;
}
button.mat-mdc-raised-button.mat-warn:focus {
  box-shadow: 2px 2px 0px $warn-400;
}

/* WARN BUTTONS - stroked */
button.mat-mdc-outlined-button.mat-warn {
  border-color: $warn-500;
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $warn-100;
}
button.mat-mdc-outlined-button.mat-warn:hover {
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $warn-200;
}
button.mat-mdc-outlined-button.mat-warn:focus {
  box-shadow: 1px 1px 0px 0 #FFF, 2px 2px 0px $warn-200;
}

/* DISABLED BUTTONS */
button.mat-mdc-raised-button[disabled="true"] {
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $grey-100;
}
button.mat-mdc-outlined-button[disabled="true"] {
  border-color: $grey-200;
  box-shadow: 1px 1px 0px 0 #FFF, 4px 4px 0px $grey-100;
}

/* *******************************************************
 * ROUNDED FORM FIELDS
 * ******************************************************/
mat-form-field.round .mdc-notched-outline__leading {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px !important;
}

mat-form-field.round .mdc-notched-outline__trailing {
  border-radius: 0 28px 28px 0 !important;
  min-width: 28px !important;
}
